import $ from 'jquery';

import Header from 'JIX/components/global/Header.js';

//function updateSticky() {
//    const header = document.querySelector('header.site-header');
//    if (!header) {
//        return;
//    }
//
//    const enable = window.scrollY > header.clientHeight;
//    document.documentElement.classList.toggle("sticky-header", enable);
//}

function generateClickLabel(str) {
    return str.toLowerCase().trim().replace(/\s+/g, '-').replace(/[.?,]/g, '');
}

function dataset(elem, name) {
    if ('dataset' in elem) {
        const nameCamel = name.replace(/-([a-z])/ig, (_, x) => x.toUpperCase());
        return elem.dataset[nameCamel];
    }
    return elem.getAttribute('data-' + name);
}

function addEvents(links, category, defaultAction) {
    for (const link of links) {
        if (window.jixAnalytics) {
            const action = dataset(link, 'click-action') || defaultAction;
            const label = generateClickLabel(dataset(link, 'click-label') || link.getAttribute('title') || link.innerText || link.textContent);
            const data = { category, action, label };
            link.addEventListener('click', () => window.jixAnalytics('event', data));
        }
    }
}

function initHeaderAnalytics() {
    const header = document.querySelector('header.site-header');
    const navBar = document.querySelector('nav.site-header');

    const category = document.body.classList.contains('jix-employer') ? 'topmenu-arbejdsgiver' : 'topmenu-jobsoeger';

    for (const elem of [header, navBar]) {
        if (elem) {
            const links = elem.querySelectorAll('a[href]');
            addEvents(links, category, 'click-top');
        }
    }
}

function initFooterAnalytics() {
    const footer = document.querySelector("#site-footer");
    if (!footer) {
        return;
    }

    const links = footer.querySelectorAll('a[href]');
    addEvents(links, 'footer', 'click');
}

function initBottomNavAnalytics() {
    const nav = document.querySelector('.nav-bottom');
    if (!nav) {
        return;
    }

    const links = nav.querySelectorAll('a[href]:not([aria-haspopup="true"])');
    addEvents(links, 'nav-bottom', 'click');
}

function initAnalytics() {
    initHeaderAnalytics();
    initFooterAnalytics();
    initBottomNavAnalytics();
}

function initLightboxes() {
    // Stop playback when modal is dismissed
    $('.modal-lightbox').on('hide.bs.modal', function () {
        const $iframe = $(this).find('iframe');
        if ($iframe.length) {
            $iframe.attr('src', $iframe.attr('src'));
        }
    });
}

function initSelectableOptgroups() {
    for (const optgroup of document.querySelectorAll('select[data-selectable-optgroups] optgroup')) {
        optgroup.addEventListener('click', (event) => {
            // Checking the event target is not enough: When dragging the mouse
            // over multiple options the click event will trigger on the
            // optgroup when releasing the mouse. To catch this case we also
            // check that the click event is inside the optgroup element.
            if (event.target === optgroup && document.elementFromPoint(event.x, event.y) === optgroup && !optgroup.disabled) {
                if (!event.ctrlKey) {
                    optgroup.parentElement.querySelectorAll('option').forEach(x => x.selected = false);
                }
                optgroup.querySelectorAll('option').forEach(x => x.selected = true);
                optgroup.parentElement.dispatchEvent(new Event('change'));
            }
        });
    }
}

new Header();

initLightboxes();
initSelectableOptgroups();

// This can wait until DCL
$(initAnalytics);

// Not used for now:
//updateSticky();
//window.addEventListener('scroll', updateSticky);
