const MEGAMENU_HOVER_ENABLE = true;
const MEGAMENU_HOVER_DELAY = 500; // https://www.nngroup.com/articles/mega-menus-work-well/ (see: Timing Considerations)

function getOuterHeight(el) {
    const style = getComputedStyle(el);
    const height = el.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return height;
}

function setAriaExpand(el, state) {
    el.setAttribute('aria-expanded', state);
    el.setAttribute('aria-hidden', !state);
}

export default class Header {
    constructor() {
        this.mainNav = document.querySelector("#main-navigation");
        this.onClick = this.onClick.bind(this);
        this.onProfileClick = this.onProfileClick.bind(this);

        if (this.mainNav) {
            this.init();
        }
    }

    init() {
        this.top = document.documentElement;

        const burger = document.querySelector(".hamburger");
        this.burgerContainer = document.querySelector(".hamburger-container");

        this.profiles = document.querySelectorAll(".open-profile");
        this.profileContainer = document.querySelector(".profile-container");
        this.profileBox = document.querySelector(".profile-box");


        this.logins = document.querySelectorAll(".open-login");
        this.loginContainer = document.querySelector(".login-container");
        this.loginBox = document.querySelector(".login-box");
        this.loginDropdownLinks = document.querySelectorAll(".login-dropdown-link");


        for (const login of this.logins) {
            login.addEventListener("click", event => this.onLoginClick(event, login));
        }

        for (const dropdownLink of this.loginDropdownLinks) {
            // Arrow function is necessary to ensure this is properly defined.
            dropdownLink.addEventListener('click', () => this.closeLoginContainer());
        }


        if (burger) {
            burger.addEventListener("click", event => this.onClick(event, burger));
            document.querySelector(".mobile-background").addEventListener("click", () => this.setMobileMenuState(false));

            // FIXME: Disable this for now:
            //this.initSwipeClose();
        }

        for (const profile of this.profiles) {
            profile.addEventListener("click", event => this.onProfileClick(event, profile));
        }

        if (this.mainNav) {
            this.initMainNav();
        }
    }

    initMainNav() {
        const curtain = document.querySelector(".megamenu-curtain");
        const megamenus = this.mainNav.querySelectorAll(".megamenu");
        const mainNavLis = this.mainNav.querySelectorAll("ul li");

        let openedByClick = null;
        const closeMegamenus = () => {
            for (const megamenu of megamenus) {
                megamenu.style.height = "0px";
                setAriaExpand(megamenu, false);
            }
            curtain.style.height = "0px";

            for (const li of mainNavLis) {
                li.classList.remove("active");
                const mainLink = li.querySelector('.main-nav-link');
                if (mainLink) {
                    mainLink.setAttribute('aria-expanded', false);
                }
            }

            document.documentElement.classList.remove("showing-megamenu");
        };

        const openMegamenu = (mainNavLink) => {
            const closestLI = mainNavLink.parentElement;
            const closestDropdown = closestLI.querySelector(".megamenu");

            closeMegamenus();

            document.documentElement.classList.add("showing-megamenu");

            // Add active to the current one
            closestLI.classList.add("active");
            setAriaExpand(closestDropdown, true);
            mainNavLink.setAttribute('aria-expanded', true);

            // Calculate height
            const height = getOuterHeight(
                closestDropdown.querySelector(".row")
            );

            closestDropdown.style.height = curtain.style.height = height + "px";
        };

        const megamenuButtons = this.mainNav.querySelectorAll('.main-nav-link[role=button]');
        const megamenuClickHandler = (event) => {
            event.preventDefault();

            const mainNavLink = event.currentTarget;
            const isActive = mainNavLink.getAttribute('aria-expanded') === 'true';

            if (isActive) {
                if (openedByClick !== mainNavLink) {
                    openedByClick = mainNavLink;
                } else {
                    openedByClick = null;
                    closeMegamenus();
                }
            } else {
                openedByClick = mainNavLink;
                openMegamenu(mainNavLink);
            }
        };
        for (const button of megamenuButtons) {
            button.addEventListener("click", megamenuClickHandler);
        }

        if (MEGAMENU_HOVER_ENABLE) {
            let activeHover = null;
            let enterTimeoutID;
            const mouseenterHandler = (event) => {
                const li = event.target;
                activeHover = li;
                if (enterTimeoutID) {
                    clearTimeout(enterTimeoutID);
                }
                enterTimeoutID = setTimeout(() => {
                    if (activeHover === li) {
                        openMegamenu(li.querySelector('.main-nav-link'));
                        this.closeProfileContainer();
                        this.closeLoginContainer();
                    }
                }, MEGAMENU_HOVER_DELAY);
            };
            const mouseleaveHandler = () => {
                activeHover = null;
                setTimeout(() => {
                    if (activeHover === null && openedByClick === null) {
                        openedByClick = null;
                        closeMegamenus();
                    }
                }, MEGAMENU_HOVER_DELAY);
            };

            for (const button of megamenuButtons) {
                const li = button.parentElement;
                li.addEventListener("mouseenter", mouseenterHandler);
                li.addEventListener("mouseleave", mouseleaveHandler);
            }
        }

        // Close megamenu when clicking outside it
        document.addEventListener("click", event => {
            if (![this.mainNav, curtain].some(x => x.contains(event.target))) {
                openedByClick = null;
                closeMegamenus();
            }
            if (this.profiles && ![...this.profiles, this.profileContainer].some(x => x.contains(event.target))) {
                this.closeProfileContainer();
            }
            if (this.logins && ![...this.logins, this.loginContainer].some(x => x?.contains(event.target))) {
                this.closeLoginContainer();
            }
        });
    }

    initSwipeClose() {
        // Swipe at least this amount of pixels before closing
        const touchSwipeDistance = 100;

        let touchStart;
        let triggerClose = false;
        let startOpacity;
        let startContainerWidth;
        let hasMoved = false;
        const container = document.querySelector('.container-navigation');
        const backdrop  = document.querySelector('.mobile-background');

        const isOpen = () => this.top.classList.contains('active-menu');

        document.addEventListener('touchstart', (event) => {
            if (isOpen()) {
                touchStart = event.touches[0];
                startContainerWidth = container.clientWidth;
                startOpacity = getComputedStyle(backdrop).opacity;
            }
        }, false);

        const move = (deltaX) => {
            hasMoved = true;
            const translate = Math.min(deltaX, 0);
            const progress = Math.max(Math.abs(translate) / startContainerWidth, 0);

            backdrop.style.opacity = (1 - progress) * startOpacity;

            container.style.transitionTimingFunction = 'linear';
            container.style.transform = 'translateX(' + translate + 'px)';
        };

        const reset = () => {
            hasMoved = false;
            container.style.transform = null;
            container.style.transitionTimingFunction = null;
            backdrop.style.opacity = null;
            touchStart = null;
        };

        this.resetSwipe = reset;

        document.addEventListener('touchmove', (event) => {
            if (!touchStart) {
                return;
            }
            const touchEnd = event.touches[0];

            const deltaX = touchEnd.clientX - touchStart.clientX;
            const deltaY = touchEnd.clientY - touchStart.clientY;

            // Make sure that the primary swipe direction is along the x-axis
            // to prevent closing when the user is scrolling up/down at a
            // slight angle.
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                move(deltaX);
                triggerClose = deltaX < -touchSwipeDistance;
            }
        }, false);

        document.addEventListener('touchend', () => {
            if (!hasMoved) {
                return;
            }
            if (triggerClose) {
                this.setMobileMenuState(false);
                triggerClose = false;
            } else {
                move(0);
            }
            reset();
        }, false);
    }

    setMobileMenuState(open) {
        this.top.classList.toggle("active-menu", open);
        // FIXME: Disable this for now:
        //this.resetSwipe();
    }

    onClick() {
        const current = this.top.classList.contains('active-menu');
        this.setMobileMenuState(!current);
    }

    closeProfileContainer() {
        for (const profile of this.profiles) {
            profile.classList.remove("active");
            this.profileContainer.style.height = "0px";
            profile.closest(".logged-in").classList.remove("is-active");
            setAriaExpand(profile, false);
            setAriaExpand(this.profileContainer, false);
        }
    }

    onProfileClick(event, profile) {
        event.preventDefault();
        const boxHeight = this.profileBox.offsetHeight;

        if (profile.classList.contains("active")) {
            this.closeProfileContainer();
        } else {
            profile.classList.add("active");
            this.profileContainer.style.height = boxHeight + "px";
            profile.closest(".logged-in").classList.add("is-active");
            this.setMobileMenuState(false); // close mobile menu when opening profile menu
            setAriaExpand(profile, true);
            setAriaExpand(this.profileContainer, true);
        }
    }

    closeLoginContainer() {
        for (const login of this.logins) {
            const dropdownLi = login.closest('li');
            dropdownLi.classList.remove("active");
            this.loginContainer.style.height = "0px";
            setAriaExpand(login, false);
            setAriaExpand(this.loginContainer, false);
        }
    }

    onLoginClick(event, login) {
        event.preventDefault();
        const boxHeight = this.loginBox.offsetHeight;

        const dropdownLi = login.closest('li');

        if (dropdownLi.classList.contains("active")) {
            this.closeLoginContainer();
        } else {
            dropdownLi.classList.add("active");
            this.loginContainer.style.height = boxHeight + "px";
            this.setMobileMenuState(false); // close mobile menu when opening login menu
            setAriaExpand(login, true);
            setAriaExpand(this.loginContainer, true);
        }
    }


}
